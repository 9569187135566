import React, { Fragment } from "react";

import FAQ from "./FAQ";
import Video from "./Video";
import VideosComponent from "./Videos";
import Gallery from "./Gallery";
import Content from "./Content";
import FormComponent from "./Form";
import News from "./News";

class Layout extends React.Component {
  getTemplate(layout, props) {
    switch (layout.acf_fc_layout) {
      case "faq":
        return <FAQ {...layout} />;
      case "video":
        return <Video {...layout} />;
      case "videos":
        return <VideosComponent {...layout} />;
      case "form":
        return <FormComponent {...layout} />;
      case "text":
        return <Content {...layout} />;
      case "gallery":
        return <Gallery {...layout} />;
      case "news":
        return <News {...layout} {...props} />;
      default:
        return null;
    }
  }

  render() {
    return (
      <Fragment>
        {this.props.layout.map((l) => (
          <div>{this.getTemplate(l, this.props)}</div>
        ))}
      </Fragment>
    );
  }
}

export default Layout;
