import React, { Component } from 'react'
import Slider from 'react-slick'
import { StyleSheet, css } from 'glamor/aphrodite'
import { variables, Icon } from '@website2018/da-dobsonville'
import Media from 'react-media'
import './slider.css'

export default class SliderWrapper extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }

  render() {
    const { col } = this.props
    const styles = StyleSheet.create({
      slider: {
        position: 'relative'
      }
    })

    const settings = {
      dots: true,
      infinite: true,
      draggable: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            draggable: true
          }
        }
      ]
    }

    return [
      <div className={`${col} ${css(styles.slider)}`}>
        <Slider ref={c => (this.slider = c)} {...settings}>
          {this.props.children}
        </Slider>

        <Media
          query="(min-width: 1385px)"
          defaultMatches={false}
          render={() => <SliderArrows next={this.next} previous={this.previous} />}
        />
      </div>
    ]
  }
}
SliderWrapper.defaultProps = {
  col: 'col-lg-12 ml-auto mr-auto'
}

class SliderArrows extends React.Component {
  render() {
    return (
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          position: 'absolute',
          width: '120%',
          left: '-10%',
          top: '50%',
          marginTop: '-61px',
          zIndex: '99'
        }}
      >
        <NextArrow onClick={this.props.previous} />
        <PrevArrow onClick={this.props.next} />
      </div>
    )
  }
}

function NextArrow(props) {
  const { className, style, onClick } = props
  const { colors } = variables
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        ...style,
        borderRadius: '50%',
        width: '66px',
        height: '66px',
        border: '2px solid',
        borderColor: colors.borderLight,
        background: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Icon fs="32px" name="angle-left" color={colors.borderLight} />
    </div>
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  const { colors } = variables
  return (
    <div
      className={className}
      style={{
        ...style,
        borderRadius: '50%',
        width: '66px',
        height: '66px',
        border: '2px solid',
        borderColor: colors.borderLight,
        background: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      onClick={onClick}
    >
      <Icon fs="32px" name="angle-right" color={colors.borderLight} />
    </div>
  )
}
