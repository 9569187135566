import React from 'react'
import {
  CardNews,
  SectionFull,
  Sidebar,
  ViewAll
} from '@website2018/da-dobsonville'
import { Link } from 'react-router-dom'
import LazyTwitter from '../Twitter/LazyTwitter'
export const dateRegex = /^([0-9]{4})-([0-1][0-9])-([0-3][0-9])T([0-2][0-9]):([0-5][0-9]):([0-5][0-9])/

const getSlug = (p, props) => {
  const [, year, month] = dateRegex.exec(p.date)

  if (props.mn) {
    return `/government/${props.government}/${year}/${month}/${p.slug}`
  }

  return `/${year}/${month}/${p.slug}`
}

const Posts = ({ title, posts, identity, identifier, stickies = [], ...restArgs }) => {
  if (!posts.length) return null

  return (
    <SectionFull title={title} mt="large">
      <div className="col-lg-8">
        <div className="row">
          {stickies.map((p, i) => {
            let cn = 'col-md-12'

            return (
              <div className={cn} key={p.id}>
                <Link to={getSlug(p, restArgs)} className="linkOver">
                  <CardNews
                    full={i === 0}
                    imageUrl={p.featured_media ? p.featured_media.source_url : null}
                    title={p.title.rendered}
                    meta={
                      p.category && p.category.length
                        ? `${p.formattedDate} in ${p.category[0].name}`
                        : `${p.formattedDate}`
                    }
                  />
                </Link>
              </div>
            )
          })}

          {posts.map((p, i) => {
            let cn = 'col-md-6'
            if (i === 0 && !stickies.length) cn = 'col-md-12'
            const imageUrl = p.featured_media
              ? ((p.featured_media.media_details
                    && p.featured_media.media_details.sizes
                    && p.featured_media.media_details.sizes.medium)
                ? p.featured_media.media_details.sizes.medium.source_url
                : p.featured_media.source_url)
              : null
            return (
              <div className={cn} key={p.id}>
                <Link to={getSlug(p, restArgs)} className="linkOver">
                  <CardNews
                    full={i === 0 && !stickies.length}
                    imageUrl={imageUrl}
                    title={p.title.rendered}
                    meta={
                      p.category && p.category.length
                        ? `${p.formattedDate} in ${p.category[0].name}`
                        : `${p.formattedDate}`
                    }
                  />
                </Link>
              </div>
            )
          })}
        </div>
        <Link to={restArgs.more || '/newsroom'}>
          <ViewAll>View more news</ViewAll>
        </Link>
      </div>
      <Sidebar className="col-lg-3 ml-auto">
        <LazyTwitter identity={identity} height={700} />
      </Sidebar>
    </SectionFull>
  )
}

Posts.defaultProps = {
  mn: false
}

export default Posts
