import React, { Fragment } from 'react'
import { Heading, CardVideo } from '@website2018/da-dobsonville'
import YouTubeVideo from './YouTubeVideo'

const VideoComponent = ({ video, video_heading }) => {
  return (
    <Fragment>
      {video_heading ? <Heading mb="medium" color="blue" html={video_heading} /> : null}
      <YouTubeVideo
        videoId={video.youtube_id}
        render={() => (
          <CardVideo
            videoId={video.youtube_id}
            featured
            imageUrl={null}
            title={video.headline}
            paragraph={video.content}
            cta="Play Video"
          />
        )}
      />
    </Fragment>
  )
}

export default VideoComponent
