import { gql } from "@apollo/client";
import React from "react";
import { graphql } from "@apollo/client/react/hoc";
import Posts from "../../components/Newsroom/Posts";

const News = ({ headline, twitter_handle, provincial }) => {
  if (provincial && provincial.news) {
    return (
      <Posts
        identifier="screen_name"
        identity={twitter_handle && twitter_handle}
        posts={provincial.news}
        title={headline}
      />
    );
  }

  return null;
};

const NEWS_QUERY = gql`
  query getVideos($site: String!, $tag: String) {
    provincial(site: $site) {
      news(tag: $tag) {
        id
        date
        slug
        formattedDate
        title {
          rendered
        }
        category {
          id
          name
        }
        featured_media {
          id
          source_url
          title {
            rendered
          }
        }
      }
    }
  }
`;

const withNews = graphql(NEWS_QUERY, {
  options: (props) => {
    let url;
    if (process.browser) {
      url = window.location.host;
    } else {
      url = props.url;
    }

    return {
      variables: {
        site: url,
        tag: props.news_tag,
      },
    };
  },
  props: ({ data }) => ({ ...data }),
});

export default withNews(News);
