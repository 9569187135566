import React, { Fragment } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { Loading } from "@website2018/da-dobsonville";
import { gql } from "@apollo/client";
import {
  CardVideo,
  SectionFull,
  ViewAll,
} from "@website2018/da-dobsonville";
import Media from "react-media";
import YouTubeVideo from "../../components/Videos/YouTubeVideo";

const Videos = (props) => {
  if (!props.videos_category) {
    return null;
  }

  const one = props.provincialVideos[0] || null;
  const two = props.provincialVideos[1] || null;
  const three = props.provincialVideos[2] || null;

  return (
    <SectionFull
      bgColor="blueLight"
      color="white"
      title={props.videos_heading && props.videos_heading}
      mb="none"
      pb="large"
      pt="large"
    >
      <Media query="(min-width: 992px)">
        {(matches) =>
          matches ? (
            [
              <div className="col-md-8">
                <YouTubeVideo
                  videoId={one.videoId}
                  render={() => (
                    <CardVideo
                      videoId={one.videoId}
                      featured
                      imageUrl={null}
                      title={one.title.rendered}
                      paragraph={one.excerpt && one.excerpt.rendered}
                      cta="Play Video"
                      color="white"
                    />
                  )}
                />
              </div>,
              <div className="col-md-4">
                {two && (
                  <YouTubeVideo
                    videoId={two.videoId}
                    render={() => (
                      <CardVideo
                        featured
                        imageUrl={null}
                        title={two.title.rendered}
                        color="white"
                      />
                    )}
                  />
                )}
                {three && (
                  <YouTubeVideo
                    videoId={three.videoId}
                    render={() => (
                      <CardVideo
                        featured
                        imageUrl={null}
                        title={three.title.rendered}
                        color="white"
                      />
                    )}
                  />
                )}
              </div>,
            ]
          ) : (
            <div className="col-12">
              <YouTubeVideo
                videoId={one.videoId}
                render={() => (
                  <CardVideo
                    videoId={one.videoId}
                    featured
                    imageUrl={null}
                    title={one.title.rendered}
                    paragraph={one.excerpt && one.excerpt.rendered}
                    cta="Play Video"
                    color="white"
                  />
                )}
              />
            </div>
          )
        }
      </Media>
      <div className="col-md-12">
        <a href={props.view_all_link} target="_blank" rel="noopener noreferrer">
          <ViewAll
            borderColor="rgba(255,255,255,0.15)"
            buttonColor="white"
            href={props.view_all_link}
          >
            View all Videos
          </ViewAll>
        </a>
      </div>
    </SectionFull>
  );
};

class VideosComponent extends React.Component {
  render() {
    const { error, loading, provincialVideos } = this.props;

    if (loading) return <Loading />;
    if (error || !provincialVideos) return <p>{JSON.stringify(error)}</p>;
    return <Videos {...this.props} />;
  }
}

const VIDEO_QUERY = gql`
  query getVideos($site: String!, $categoryId: Int) {
    provincialVideos(site: $site, categoryId: $categoryId) {
      id
      videoId
      title {
        rendered
      }
      excerpt {
        rendered
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
      }
    }
  }
`;

const withPage = graphql(VIDEO_QUERY, {
  options: (props) => {
    let url;
    if (process.browser) {
      url = window.location.host;
    } else {
      url = props.url;
    }

    return {
      variables: {
        site: url,
        categoryId: Number(props.videos_category),
      },
    };
  },
  props: ({ data }) => ({ ...data }),
});

export default withPage(VideosComponent);
