import React from 'react'
import { Heading, Image, variables } from '@website2018/da-dobsonville'
import SliderWrapper from '../Slider/Slider'
import Spacing from '../Spacing/Spacing'

const GalleryComponent = ({ gallery: { images = [], headline } }) => {
  const { spacing } = variables
  return (
    <Spacing top size={spacing.space4}>
      {headline ? (
        <Heading mb="medium" color="blue">
          {headline}
        </Heading>
      ) : null}
      <SliderWrapper col="col-md-12">
        {images.map(t => {
          if (!t.url) return null

          return (
            <Spacing bottom size={spacing.space2}>
              <Image imageUrl={t.url} />
            </Spacing>
          )
        })}
      </SliderWrapper>
    </Spacing>
  )
}

export default GalleryComponent
