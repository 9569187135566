import React, { Fragment } from 'react'
import { Copy, Heading } from '@website2018/da-dobsonville'
import FormBuilderContainer from '../../containers/Data/FormBuilderContainer'
import FormContainer from '../../containers/Data/FormContainer'

const FormComponent = ({ text, heading, formId, inputClass }) => {
  return (
    <Fragment>
      {heading ? <Heading mb="medium" color="blue" html={heading} /> : null}
      <Copy html={text} />
      <FormContainer
        formId={formId}
        render={(form, onSubmit, meta) => (
          <FormBuilderContainer
            meta={meta}
            form={form}
            onSubmit={onSubmit}
            inputClass={inputClass}
          />
        )}
      />
    </Fragment>
  )
}

export default FormComponent
