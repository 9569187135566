import React, { Fragment } from 'react'
import { HeadingLines, Heading, ButtonTest } from '@website2018/da-dobsonville'
import Media from 'react-media'
import Spacing from '../Spacing/Spacing'

const Actions = props => {
  if (props.type === 'Group' && props.actions.length) {
    return [
      <Media
        query="(min-width: 992px)"
        render={() =>
          props.headline && (
            <div className="row justify-content-center">
              <HeadingLines lineLeft lineRight color="white" mb="large">
                <Heading color="white" size="large" align="center">
                  {props.headline}
                </Heading>
              </HeadingLines>
            </div>
          )
        }
      />,
      <div className="row justify-content-center">
        <Media query="(min-width: 992px)">
          {matches =>
            matches ? (
              <Fragment>
                {props.actions.map((a, index) => (
                  <Action index={index} {...a} />
                ))}
              </Fragment>
            ) : (
              <Fragment>
                <Action {...props.actions[0]} />
              </Fragment>
            )
          }
        </Media>
      </div>
    ]
  }

  return null
}

Actions.defaultProps = {
  actions: [],
  type: 'Group'
}

const Action = ({ title, link_label, link_url }) => {
  return (
    <div className="col text-center">
      <Spacing bottom size="space4">
        <Heading color="white" size="small" mb="small" align="center">
          {title}
        </Heading>
        <ButtonTest color={'white'} size="small" href={link_url}>
          {link_label}
        </ButtonTest>
      </Spacing>
    </div>
  )
}

export default Actions
