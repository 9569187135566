import React, { Fragment } from "react";
import {
  Hero,
  SectionFull,
  CardHighlight,
  Sidebar,
  MoreLink,
  variables,
} from "@website2018/da-dobsonville";
import { Helmet } from "react-helmet-async";
import { decode } from 'he';
import Posts from "./components/Newsroom/Posts";
import CampaignsContainer from "./containers/Data/CampaignsContainer";
import Spacing from "./components/Spacing/Spacing";
import Campaigns from "./components/Campaigns/Campaigns";
import Asides from "./components/Asides/Asides";
import PubRepsContainer from "./containers/Data/PubRepsContainer";
import Videos from "./components/Videos/Videos";
import PeopleSlider from "./components/People/PeopleSlider";
import Actions from "./components/Actions/Actions";

class HomeContainer extends React.Component {
  state = {
    client: false,
  };

  render() {
    const { site } = this.props;
    const { province: page } = site;
    const { videos } = page || [];
    const { spacing } = variables;

    const {
      options: { hero },
    } = site;
    let pageHero = null;
    if (hero) {
      pageHero = hero;
    } else if (page.hero) {
      pageHero = page.hero;
    }

    const linkRel = {};
    if (page.acf.introduction.button.link_target === "_blank") {
      linkRel.rel = "noopener noreferer";
    }

    return (
      <Fragment>
        <Helmet
          defaultTitle={
            decode(page.seo.title !== "" ? page.seo.title : page.title.rendered)
          }
          meta={[
            {
              name: "description",
              content:
                page.seo.metadesc !== ""
                  ? page.seo.metadesc
                  : page.excerpt.rendered,
            },
            {
              name: "og:title",
              content:
                page.seo["opengraph-title"] !== ""
                  ? page.seo["opengraph-title"]
                  : page.title.rendered,
            },
            {
              name: "og:description",
              content:
                page.seo["opengraph-description"] !== ""
                  ? page.seo["opengraph-description"]
                  : page.excerpt.rendered,
            },
            { name: "og:url", content: `` },
            { name: "og:site_name", content: `Democratic Alliance` },
            { name: "og:type", content: `business` },
            { name: "og:image", content: page.seo["opengraph-image"] || null },
            { name: "fb:app_id", content: "1262049647255981" },
            { name: "twitter:card", content: "”summary_large_image”" },
            { name: "twitter:site", content: "@our_da" },
            {
              name: "twitter:title",
              content:
                page.seo["twitter-title"] !== ""
                  ? page.seo["twitter-title"]
                  : page.title.rendered,
            },
            {
              name: "twitter:description",
              content:
                page.seo["twitter-description"] !== ""
                  ? page.seo["twitter-description”"]
                  : page.excerpt.rendered,
            },
            {
              name: "twitter:image",
              content:
                page.seo["twitter-image"] !== ""
                  ? page.seo["twitter-image"]
                  : null,
            },
          ]}
          htmlAttributes={{ lang: "en" }}
        />
        {hero ? (
          <Hero
            center
            imageUrl={
              pageHero.background_image ? pageHero.background_image.url : null
            }
            small={false}
            mb={"none"}
            title={pageHero.headline}
          >
            <Actions
              type={pageHero.cta_type}
              actions={pageHero.cta_group}
              headline={pageHero.group_headline}
            />
          </Hero>
        ) : null}

        {page.acf.introduction.headline !== "" && (
          <SectionFull bgColor="light" mb="none" pt="large" pb="large">
            <div className={"col-md-12"}>
              <CardHighlight
                imageUrl={
                  page.acf.introduction.image
                    ? page.acf.introduction.image.url
                    : null
                }
                title={page.acf.introduction.headline}
                paragraph={page.acf.introduction.excerpt}
              >
                {page.acf.introduction.button &&
                  page.acf.introduction.button.link_url && (
                    <Spacing bottom size={spacing.space2}>
                      <a
                        {...linkRel}
                        href={page.acf.introduction.button.link_url}
                        target={
                          page.acf.introduction.button.link_target
                            ? page.acf.introduction.button.link_target
                            : "_self"
                        }
                      >
                        <MoreLink mb="medium">
                          {page.acf.introduction.button.link_label
                            ? page.acf.introduction.button.link_label
                            : "Read more"}
                        </MoreLink>
                      </a>
                    </Spacing>
                  )}
              </CardHighlight>
            </div>
          </SectionFull>
        )}

        <CampaignsContainer
          meta_key={"campaign_featured"}
          meta_value={"featured"}
          taxonomy={"province"}
          term={site.province.slug}
          limit={5}
          render={(campaigns) =>
            campaigns.length > 0 && (
              <SectionFull mb="large" mt="large">
                <div className="col-lg-7">
                  <Spacing bottom size="space4">
                    <Campaigns
                      campaigns={campaigns}
                      title={"Latest Campaigns"}
                      view={true}
                    />
                  </Spacing>
                </div>
                <Sidebar>
                  <Asides asides={page.asides} />
                </Sidebar>
              </SectionFull>
            )
          }
        />

        <Posts
          identifier="screen_name"
          identity={site.options.twitterHandle || null}
          posts={site.news}
          title={"Newsroom"}
        />

        <PubRepsContainer
          type={"portfolio"}
          query={{
            legislature: site.options.structureCode,
          }}
          render={(people) => (
            <PeopleSlider
              highlight={page.highlight || null}
              people={people}
              title={
                page.acf.people_section_heading
                  ? page.acf.people_section_heading
                  : "Meet the members of the provincial legislature"
              }
            />
          )}
        />

        <Videos videos={videos} title={"Latest Videos"} site={site} />
      </Fragment>
    );
  }
}

export default HomeContainer;
