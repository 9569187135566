import React from "react";
import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import { Loading } from "@website2018/da-dobsonville";
import PageTemplate from "../../components/Page/PageTemplate";

class PageContainer extends React.Component {
  render() {
    const { error, loading, provincial, ...rest } = this.props;

    if (loading) return <Loading />;
    if (error) return { error };
    return <PageTemplate page={provincial.page} {...rest} />;
  }
}

const PAGE_QUERY = gql`
  query getPage($site: String!, $slug: String, $id: Int) {
    provincial(site: $site) {
      page(slug: $slug, id: $id) {
        id
        slug
        seo
        title {
          rendered
        }
        excerpt {
          rendered
        }
        content {
          rendered
        }
        featured_media {
          id
          source_url
          title {
            rendered
          }
        }
        acf
        hero {
          headline
          cta_type
          cta_group {
            title
            link_label
            link_url
          }
          background_image {
            url
          }
          focus_point
        }
        asides {
          id
          title {
            rendered
          }
          content {
            rendered
          }
          aside_options
        }
      }
    }
  }
`;

const withPage = graphql(PAGE_QUERY, {
  options: (props) => {
    let url;
    if (process.browser) {
      url = window.location.host;
    } else {
      url = props.url;
    }

    return {
      variables: {
        slug: props.slug || null,
        id: parseInt(props.id, 10) || null,
        site: url,
      },
    };
  },
  props: ({ data, post }) => ({ ...data }),
});

export default withPage(PageContainer);
