import React, { Fragment } from "react";
import {
  Copy,
  Hero,
  SectionFull,
  Sidebar,
} from "@website2018/da-dobsonville";
import { Helmet } from "react-helmet-async";
import { decode } from 'he';
import Asides from "../Asides/Asides";
import Layout from "../Layout/Base";
import NoMatch from "./NoMatch";

const PageTemplate = (props) => {
  const { page: p, ...rest } = props;

  if(!p){
    return  <NoMatch />
  }

  return [
    <Helmet
      defaultTitle={decode(p.seo.title !== "" ? p.seo.title : p.title.rendered)}
      meta={[
        {
          name: "description",
          content: p.seo.metadesc !== "" ? p.seo.metadesc : p.excerpt.rendered,
        },
        {
          name: "og:title",
          content:
            p.seo["opengraph-title"] !== ""
              ? p.seo["opengraph-title"]
              : p.title.rendered,
        },
        {
          name: "og:description",
          content:
            p.seo["opengraph-description"] !== ""
              ? p.seo["opengraph-description"]
              : p.excerpt.rendered,
        },
        { name: "og:url", content: `` },
        { name: "og:site_name", content: `Democratic Alliance` },
        { name: "og:type", content: `business` },
        { name: "og:image", content: p.seo["opengraph-image"] || null },
        { name: "fb:app_id", content: "1262049647255981" },
        { name: "twitter:card", content: "”summary_large_image”" },
        { name: "twitter:site", content: "@our_da" },
        {
          name: "twitter:title",
          content:
            p.seo["twitter-title"] !== ""
              ? p.seo["twitter-title"]
              : p.title.rendered,
        },
        {
          name: "twitter:description",
          content:
            p.seo["twitter-description"] !== ""
              ? p.seo["twitter-description”"]
              : p.excerpt.rendered,
        },
        {
          name: "twitter:image",
          content:
            p.seo["twitter-image"] !== "" ? p.seo["twitter-image"] : null,
        },
      ]}
      htmlAttributes={{ lang: "en" }}
    />,
    <Hero
      imageUrl={
        p.hero && p.hero.background_image ? p.hero.background_image.url : null
      }
      title={p.hero && p.hero.headline ? p.hero.headline : p.title.rendered}
    />,
    <Fragment>
      <SectionFull mt={"none"}>
        <div className={"col-lg-7"}>
          <article className="entry">
            {p.content.rendered ? (
              <Copy intro html={p.content.rendered} />
            ) : null}
            {p.acf.layout && p.acf.layout.length ? (
              <Layout layout={p.acf.layout} />
            ) : null}
          </article>
        </div>
        <Sidebar>{p.asides && <Asides asides={p.asides} />}</Sidebar>
      </SectionFull>
    </Fragment>,
    <Fragment>
      {p.acf.layout_sections && p.acf.layout_sections.length ? (
        <Layout layout={p.acf.layout_sections} {...rest} />
      ) : null}
    </Fragment>,
  ];
};

export default PageTemplate;
