export const getTaxLink = (key, value, base = 'search') => {
  let link = `/${base}?`

  const query = {
    page: 1,
    refinementList: {
      taxonomies: {
        [key]: [value]
      }
    }
  }

  return `${link}${encode(query)}`
}

export const sanitarize = string => {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '/': '&#x2F;'
  }
  const reg = /[&<>"'/]/gi
  return string.replace(reg, match => map[match])
}

export const dateRegex = /^([0-9]{4})-([0-1][0-9])-([0-3][0-9])T([0-2][0-9]):([0-5][0-9]):([0-5][0-9])/

export const noComma = number => {
  number = number.replace(/,/g, ' ')
  return number
}

const encode = (params, prefix = null) => {
  var items = []

  for (var field in params) {
    var key = prefix ? prefix + '[' + field + ']' : field
    var type = typeof params[field]

    switch (type) {
      case 'object':
        //handle arrays appropriately x[]=1&x[]=3
        if (params[field].constructor === Array) {
          params[field].forEach(val => {
            // eslint-disable-line no-loop-func
            items.push(key + '[]=' + val)
          })
        } else {
          //recusrively construct the sub-object
          items = items.concat(encode(params[field], key))
        }
        break
      case 'function':
        break
      default:
        items.push(key + '=' + escape(params[field]))
        break
    }
  }

  return items.join('&')
}

const postTypes = {
  posts: 'Newsroom',
  governments: 'Where we govern'
}

export const getPostTypeLabel = type => {
  if (postTypes[type.toLowerCase()]) {
    return postTypes[type.toLowerCase()]
  }

  return type
}

export const checkLink = urlString => {
  var pat = /^https?:\/\//i
  return !pat.test(urlString)
}

const postDateToSlug = timestamp => {
  const a = new Date(timestamp * 1000).toISOString()

  const [, year, month] = dateRegex.exec(a)

  return `/${year}/${month}`
}

export const getItemLink = p => {
  switch (p.post_type) {
    case 'municipality_news':
      return `/government/where-we-govern${postDateToSlug(p.post_date)}/${p.id || p.slug}`
    case 'people':
      return `/people/${p.slug}`
    case 'policies':
      return `/policy/${p.slug}`
    case 'post':
      return `${postDateToSlug(p.post_date)}/${p.id || p.slug}`
    case 'page':
      return p.permalink.replace('http://content.voteda.org/gauteng', '')
    case 'campaigns':
      return `/campaigns/${p.slug}`
    case 'govern':
      return `/government/${p.slug}`
    case 'provinces':
      return `/province/${p.slug}`
    default:
      return `/${p.slug}`
  }
}

export const validateIdNumber = idNumber => {
  let correct = true

  if (idNumber.length !== 13) {
    return false
  }

  var tempTotal = 0
  var checkSum = 0
  var multiplier = 1
  for (var i = 0; i < 13; ++i) {
    tempTotal = parseInt(idNumber.charAt(i), 10) * multiplier
    if (tempTotal > 9) {
      tempTotal =
        parseInt(tempTotal.toString().charAt(0), 10) + parseInt(tempTotal.toString().charAt(1), 10)
    }
    checkSum = checkSum + tempTotal
    multiplier = multiplier % 2 === 0 ? 1 : 2
  }

  if (checkSum % 10 !== 0) {
    correct = false
  }

  return correct
}
