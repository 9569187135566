import React from 'react'
import { CardVideo, SectionFull, ViewAll } from '@website2018/da-dobsonville'
import Media from 'react-media'
import YouTubeVideo from './YouTubeVideo'

const Videos = props => {
  if (!props.videos.length) return null

  const { youtube } = props.site.options
  const one = props.videos[0] || null
  const two = props.videos[1] || null
  const three = props.videos[2] || null

  return (
    <SectionFull
      bgColor="blueLight"
      color="white"
      title={props.title}
      mb="none"
      pb="large"
      pt="large"
    >
      <Media query="(min-width: 992px)">
        {matches =>
          matches ? (
            [
              <div className="col-md-8">
                <YouTubeVideo
                  videoId={one.videoId}
                  render={() => (
                    <CardVideo
                      videoId={one.videoId}
                      featured
                      imageUrl={null}
                      title={one.title.rendered}
                      paragraph={one.excerpt.rendered}
                      cta="Play Video"
                      color="white"
                    />
                  )}
                />
              </div>,
              <div className="col-md-4">
                {two && (
                  <YouTubeVideo
                    videoId={two.videoId}
                    render={() => (
                      <CardVideo
                        featured
                        imageUrl={null}
                        title={two.title.rendered}
                        color="white"
                      />
                    )}
                  />
                )}
                {three && (
                  <YouTubeVideo
                    videoId={three.videoId}
                    render={() => (
                      <CardVideo
                        featured
                        imageUrl={null}
                        title={three.title.rendered}
                        color="white"
                      />
                    )}
                  />
                )}
              </div>
            ]
          ) : (
            <div className="col-12">
              <YouTubeVideo
                videoId={one.videoId}
                render={() => (
                  <CardVideo
                    videoId={one.videoId}
                    featured
                    imageUrl={null}
                    title={one.title.rendered}
                    paragraph={one.excerpt.rendered}
                    cta="Play Video"
                    color="white"
                  />
                )}
              />
            </div>
          )
        }
      </Media>
      <div className="col-md-12">
        <a href={youtube} target="_blank" rel="noopener noreferrer">
          <ViewAll borderColor="rgba(255,255,255,0.15)" buttonColor="white" href={youtube}>
            View all Videos
          </ViewAll>
        </a>
      </div>
    </SectionFull>
  )
}

export default Videos
