import React, { Component } from 'react'
import { Alert, Copy, Heading, ButtonTest } from '@website2018/da-dobsonville'
import { graphql } from "@apollo/client/react/hoc";
import { compose } from 'recompose';
import serializeForm from 'form-serialize'
import { withRouter } from 'react-router'
import { gql } from '@apollo/client'
import TextField from '../../components/Form/TextField'
import SelectField from '../../components/Form/SelectField'
import RadioField from '../../components/Form/RadioField'
import TextAreaField from '../../components/Form/TextAreaField'
import MultiInput from '../../components/Form/MultiField'
import Upload from '../../components/Form/Upload'


import * as R from 'ramda'
import { validateIdNumber } from '../../common/utils'

class FormBuilderContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      errors: [],
      sent: false,
      loading: false,
      form: {}
    }
  }

  validate = values => {
    const errors = {}

    const {
      form: { fields },
      values: stateValues
    } = this.props

    fields.forEach(field => {
      if (field.type === 'file' && field.required && this.showField(field)) {
        const getFile = R.path(['uploads', field.id])
        if (!getFile(stateValues)) {
          errors[field.id] = 'Required Field'
        }
      } else if (field.required && !values[field.id] && this.showField(field)) {
        errors[field.id] = 'Required Field'
      } else if (
        field.validate === 'phone' &&
        !/^0(6[0-9]|7[0-46-9]|8[1-4])\d{7}$/i.test(values[field.id]) &&
        this.showField(field)
      ) {
        errors[field.id] = 'Invalid phone number'
      } else if (
        field.validate === 'email' &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[field.id]) &&
        this.showField(field)
      ) {
        errors[field.id] = 'Invalid email address'
      } else if (
        field.validate === 'idnumber' &&
        this.showField(field) &&
        !validateIdNumber(values[field.id])
      ) {
        errors[field.id] = 'Invalid ID Number'
      }
    })

    return errors
  }

  componentDidUpdate() {}

  getErrors(id) {
    const { errors } = this.state
    const index = Object.keys(errors).indexOf(id)
    if (index > -1) {
      return errors[Object.keys(errors)[index]]
    }

    return null
  }

  getField(field) {
    const {
      form,
      inputClass = null,
      inputOptions = null,
      fileOptions = null,
      readOnly
    } = this.props

    let fieldOptions

    if (field.type === 'file' && fileOptions) {
      fieldOptions = {
        ...field,
        inputOptions: {
          onChange: fileOptions.onChange,
          file:
            fileOptions.uploads && fileOptions.uploads[field.id]
              ? fileOptions.uploads[field.id]
              : null,
          errors:
            fileOptions.errors && fileOptions.errors[field.id] ? fileOptions.errors[field.id] : null
        }
      }
    } else if (inputOptions) {
      fieldOptions = {
        ...field,
        inputOptions: {
          onChange: inputOptions.onChange,
          readOnly: readOnly[field.id]
        }
      }
    } else if (form.stateManage) {
      fieldOptions = {
        ...field,
        inputOptions: {
          onChange: e => {
            if (field.type === 'checkbox') {
              const newValue = e.target.value

              if (e.target.checked) {
                this.setState(state => ({
                  form: {
                    ...state.form,
                    [field.id]: state.form[field.id]
                      ? [...state.form[field.id], newValue]
                      : [newValue]
                  }
                }))
              } else {
                this.setState(state => ({
                  form: {
                    ...state.form,
                    [field.id]: state.form[field.id].filter(v => v !== newValue)
                  }
                }))
              }
            }
          }
        }
      }
    } else {
      fieldOptions = field
    }

    switch (field.type) {
      case 'hidden':
      case 'email':
      case 'text':
      case 'date':
        return (
          <TextField
            {...fieldOptions}
            inputClass={inputClass}
            error={this.getErrors(fieldOptions.id)}
            key={fieldOptions.id}
          />
        )
      case 'select':
        return (
          <SelectField
            {...fieldOptions}
            inputClass={inputClass}
            error={this.getErrors(fieldOptions.id)}
            key={fieldOptions.id}
          />
        )
      case 'multicheck':
        return null // <MultiCheck {...field} />
      case 'checkbox':
      case 'radio':
        return (
          <RadioField
            {...fieldOptions}
            inputClass={inputClass}
            error={this.getErrors(fieldOptions.id)}
            key={fieldOptions.id}
          />
        )
      case 'section':
        return (
          <div className="col-md-12">
            <Heading level={6} mt="small" mb="small" color={'blue'}>
              {fieldOptions.label}
            </Heading>
          </div>
        )
      case 'copy':
        return (
          <div className="col-md-12">
            <Copy size="small">{fieldOptions.label}</Copy>
          </div>
        )
      case 'textarea':
        return <TextAreaField {...fieldOptions} />
      case 'break':
        return <div className="col-md-12" />
      case 'file':
        return <Upload {...fieldOptions} error={this.getErrors(fieldOptions.id)} />
      case 'multi':
        return <MultiInput {...field} />
      default:
        return <TextField {...fieldOptions} />
    }
  }

  getFieldConditional = field => {
    const { form } = this.props

    if (field.conditional) {
      const math_it_up = {
        is: (x, y) => {
          return x === y
        },
        isnot: (x, y) => {
          return x !== y
        },
        includes: (x, y) => {
          return x.includes(y)
        },
        interect: (x, y) => {
          var z = x.filter(function(val) {
            return y.split(',').indexOf(val) !== -1
          })
          return z.length > 0
        }
      }

      let currentValues = {}

      if (form.stateManage) {
        currentValues = this.state.form
      } else {
        const { values = {} } = this.props
        currentValues = values
      }

      const {
        conditionalLogic: { actionType, logic }
      } = field

      let a

      if (typeof currentValues[logic.field] === 'undefined') {
        a = false
      } else {
        a = math_it_up[logic.match](currentValues[logic.field], logic.value)
      }

      if (a) {
        if (actionType === 'show') {
          return true
        } else {
          return false
        }
      } else {
        if (actionType === 'show') {
          return false
        } else {
          return true
        }
      }
    }

    return true
  }

  createField(field) {
    const shouldShow = this.getFieldConditional(field)
    if (shouldShow) return this.getField(field)
    return null
  }

  showField(field) {
    const shouldShow = this.getFieldConditional(field)
    if (shouldShow) {
      return true
    }

    return false
  }

  onSubmit = e => {
    e.preventDefault()

    const values = serializeForm(e.target, { hash: true })
    const errors = this.validate(values)
    this.setState({
      errors: [],
      sent: false,
      loading: true
    })

    if (Object.keys(errors).length) {
      this.setState({
        errors,
        loading: false,
        sent: false
      })
      return
    }

    if (this.props.handleSubmit) {
      return this.props.handleSubmit(values, errors).then(data => {
        this.setState({
          loading: false,
          sent: true,
          message: 'Done'
        })

        this.form.reset()
      })
    } else {
      const {
        form: { confirmation, id, submission = null },
        initValues = {}
      } = this.props

      if (submission === 'subscription') {
        this.props
          .createSubscription({
            variables: {
              input: {
                form: id,
                ...values
              }
            }
          })
          .then(() => {
            if (confirmation.type === 'message') {
              this.setState({
                errors: [],
                error: null,
                sent: true,
                loading: false,
                message: confirmation.label
              })
              this.form.reset()
            } else if (confirmation.type === 'redirect') {
              this.props.history.push(confirmation.to)
            }
          })
          .catch(err => {
            this.setState({
              errors: [],
              error: err.message || err,
              sent: false,
              loading: false
            })
          })
      } else if (id === 'unsubscribe') {
        this.props
          .submitEvent({
            variables: {
              input: {
                form: id,
                fields: {
                  ...values,
                  ...this.state.form
                }
              }
            }
          })
          .then(() => {
            if (confirmation.type === 'message') {
              this.setState({
                errors: [],
                error: null,
                sent: true,
                loading: false,
                message: 'Successfully Unsubscribed.'
              })
            } else if (confirmation.type === 'redirect') {
              this.props.history.push(confirmation.to)
            }

            this.form.reset()
          })
          .catch(err => {
            this.setState({
              errors: [],
              error: err.message || err,
              sent: false,
              loading: false
            })
          })
      } else if (submission === 'submission' || submission === 'mailer') {
        this.props
          .submitForm({
            variables: {
              input: {
                form: id,
                admin_email: this.props.admin_email || null,
                fields: {
                  ...values,
                  ...initValues
                }
              }
            }
          })
          .then(() => {
            if (confirmation.type === 'message') {
              this.setState({
                errors: [],
                error: null,
                sent: true,
                loading: false,
                message: confirmation.label
              })
            } else if (confirmation.type === 'redirect') {
              this.props.history.push(confirmation.to)
            }

            this.form.reset()
          })
          .catch(err => {
            this.setState({
              errors: [],
              error: err.message || err,
              sent: false,
              loading: false
            })
          })
      } else {
        this.props
          .submitEvent({
            variables: {
              input: {
                form: id,
                fields: {
                  ...values
                }
              }
            }
          })
          .then(() => {
            if (confirmation.type === 'message') {
              this.setState({
                errors: [],
                error: null,
                sent: true,
                loading: false,
                message: confirmation.label
              })
            } else if (confirmation.type === 'redirect') {
              this.props.history.push(confirmation.to)
            }

            this.form.reset()
          })
          .catch(err => {
            this.setState({
              errors: [],
              error: err.message || err,
              sent: false,
              loading: false
            })
          })
      }
    }
  }

  render() {
    const { form, buttonClass } = this.props
    const { errors, loading, sent, error, message = '' } = this.state
    return (
      <form onSubmit={this.onSubmit} ref={node => (this.form = node)}>
        {sent ? (
          <Alert close={false} status="success">
            {message}
          </Alert>
        ) : null}
        {error ? (
          <Alert close={false} status="danger">
            {error}
          </Alert>
        ) : null}
        <div className="row">
          {form.fields.map(field => this.createField(field))}

          <div className={buttonClass}>
            <ButtonTest type="submit" full disabled={loading}>
              {loading ? 'Loading...' : form.buttonLabel}
            </ButtonTest>
            {Object.keys(errors).length ? (
              <Alert close={false} status="danger">
                There is a problem with your submission. Please check that you have correctly filled
                in your information and completed all required fields.{' '}
              </Alert>
            ) : null}
          </div>
        </div>
      </form>
    )
  }
}

FormBuilderContainer.defaultProps = {
  form: null,
  onSubmit: null,
  buttonClass: 'col-md-12'
}

const ADD_SUBSCRITION_MUTATION = gql`
  mutation createSubscription($input: SubscriptionInput!) {
    createSubscription(input: $input)
  }
`

const ADD_SUBMISSION_MUTATION = gql`
  mutation submitForm($input: SubmissionInput!) {
    submitForm(input: $input)
  }
`

const ADD_EVENT_MUTATION = gql`
  mutation submitEvent($input: SubmissionInput!) {
    submitEvent(input: $input)
  }
`

const withSubscription = graphql(ADD_SUBSCRITION_MUTATION, {
  name: 'createSubscription'
})
const withSubmission = graphql(ADD_SUBMISSION_MUTATION, { name: 'submitForm' })
const withEvent = graphql(ADD_EVENT_MUTATION, { name: 'submitEvent' })

export default compose(
  withSubscription,
  withSubmission,
  withEvent,
  withRouter
)(FormBuilderContainer)
