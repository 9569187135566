import React from 'react'
import { Border, NavIcon } from '@website2018/da-dobsonville'
import TextField from './TextField'
import SelectField from './SelectField'

class MultiInput extends React.Component {
  state = {
    inputs: [
      {
        enabled: true
      }
    ]
  }

  updateObjectInArray(array, action) {
    return array.map((item, index) => {
      if (index !== action.index) {
        // This isn't the item we care about - keep it as-is
        return item
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...item,
        [action.name]: action.value
      }
    })
  }

  updateMulti = (e, index, id) => {
    this.setState({
      inputs: this.updateObjectInArray(this.state.inputs, {
        index,
        name: id,
        value: e.target.value
      })
    })
  }

  getField(field, index, name, value) {
    const { inputClass = null } = this.props
    switch (field.type) {
      case 'select':
        return (
          <SelectField
            {...field}
            id={`${name}[${index}][${field.id}]`}
            inputClass={inputClass}
            key={`input_${name}[${index}][${field.id}]`}
            inputOptions={{
              value,
              onChange: e => this.updateMulti(e, index, field.id)
            }}
          />
        )
      default:
      case 'email':
      case 'text':
      case 'date':
        return (
          <TextField
            {...field}
            id={`${name}[${index}][${field.id}]`}
            inputClass={inputClass}
            key={`input_${name}[${index}][${field.id}]`}
            inputOptions={{
              value: this.state.inputs[index][field.id],
              onChange: e => this.updateMulti(e, index, field.id)
            }}
          />
        )
    }
  }

  addInput = () => {
    if (this.state.inputs.length >= 5) return
    this.setState(state => {
      var newArray = state.inputs.slice()
      newArray.push({ enabled: true })

      return {
        inputs: newArray
      }
    })
  }

  removeItem(array, action) {
    let newArray = array.slice()
    newArray.splice(action.index, 1)
    return newArray
  }

  removeInput = index => {
    this.setState(state => ({
      inputs: this.removeItem(state.inputs, { index })
    }))
  }

  render() {
    const { fields, id } = this.props
    const { inputs } = this.state
    return (
      <div className="col-md-12">
        {inputs.map((i, index) => {
          return (
            <div className="row">
              <div className="col-md-10">
                <div className="row">{fields.map(f => this.getField(f, index, id, i.value))}</div>
              </div>
              <div className="col-md-2">
                <div className="d-flex align-items-stretch" style={{ height: '100%' }}>
                  <NavIcon onPress={() => this.removeInput(index)} iconName="minus">
                    Remove
                  </NavIcon>
                </div>
              </div>
            </div>
          )
        })}
        {
          <NavIcon onPress={() => this.addInput()} iconName="plus">
            Add
          </NavIcon>
        }
        <Border />
      </div>
    )
  }
}

export default MultiInput
