import React from 'react'
import { Label, Copy } from '@website2018/da-dobsonville'

const TextAreaField = ({ id, className, error, label, type, placeholder, description }) => {
  return (
    <div className={`${className} form-group`}>
      <Label>{label}</Label>
      <textarea name={id} placeholder={placeholder} type={type} className="form-control" />
      {error && (
        <Copy mb="0" color="red" size="small">
          {error}
        </Copy>
      )}
      {description ? (
        <small>
          <em>{description}</em>
        </small>
      ) : null}
    </div>
  )
}

TextAreaField.defaultProps = {
  className: 'col-md-6 form-group'
}

export default TextAreaField
