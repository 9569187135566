import React from 'react'
import { Label, Copy } from '@website2018/da-dobsonville'

const TextField = ({
  id,
  inputClass,
  className,
  error,
  label,
  type,
  placeholder,
  description,
  inputOptions = {}
}) => {
  if (type === 'hidden') {
    return (
      <input defaultValue={inputOptions.readOnly || null} name={id} type={type} {...inputOptions} />
    )
  }

  return (
    <div className={`${inputClass ? inputClass : className} form-group`}>
      <Label>{label}</Label>
      <input
        defaultValue={inputOptions.readOnly || null}
        readOnly={inputOptions.readOnly ? true : false}
        name={id}
        placeholder={placeholder}
        type={type}
        className="form-control"
        {...inputOptions}
      />
      {error && (
        <Copy mb="0" color="red" size="small">
          {error}
        </Copy>
      )}
      {description ? (
        <small>
          <em>{description}</em>
        </small>
      ) : null}
    </div>
  )
}

TextField.defaultProps = {
  className: 'col-md-6 form-group'
}

export default TextField
