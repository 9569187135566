import React, { Fragment } from 'react'
import { Copy, Heading } from '@website2018/da-dobsonville'

const ContentComponent = ({ text, heading }) => {
  return (
    <Fragment>
      {heading ? <Heading mb="medium" color="blue" html={heading} /> : null}
      <Copy html={text} />
    </Fragment>
  )
}

export default ContentComponent
