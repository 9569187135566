import React from 'react'
import PropTypes from 'prop-types'
import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

class CampaignsContainer extends React.Component {
  static propTypes = {
    render: PropTypes.func.isRequired
  }

  render() {
    const { loading, campaigns, error } = this.props
    if (loading) return null
    if (error) return null
    if (!campaigns.length) return null

    return this.props.render(campaigns)
  }
}

const POST_QUERY = gql`
  query getMenu(
    $limit: Int
    $meta_value: String
    $meta_key: String
    $taxonomy: String
    $term: String
  ) {
    campaigns(
      first: $limit
      meta_key: $meta_key
      meta_value: $meta_value
      taxonomy: $taxonomy
      term: $term
    ) {
      id
      slug
      title {
        rendered
      }
      excerpt {
        rendered
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
        media_details {
          sizes {
            medium_large { source_url }
          }
        }
      }
      province {
        id
        name
      }
      external {
        status
        link
      }
    }
  }
`

CampaignsContainer.defaultProps = {
  limit: 9,
  taxonomy: null,
  term: null,
  meta_key: null,
  meta_value: null
}

const withPosts = graphql(POST_QUERY, {
  options: props => ({
    variables: {
      ...props
    }
  }),
  props: ({ data }) => ({
    ...data
  })
})

export default withPosts(CampaignsContainer)
