import React, { Fragment } from 'react'
import {
  CardCampaign,
  Heading,
  HeadingLines,
  MoreLink,
  ViewAll
} from '@website2018/da-dobsonville'
import { Link } from 'react-router-dom'

const Campaigns = props => {
  if (!props.campaigns.length) return null

  return (
    <Fragment>
      <HeadingLines lineLeft color={'red'} mb={'large'}>
        <Heading color={'red'} tracking={'huge'} size={'tiny'} weight={'regular'} uppercase>
          {props.title}
        </Heading>
      </HeadingLines>
      <div className="row">
        {props.campaigns.map(c => {
          const imageUrl = c.featured_media ? (
            (c.featured_media.media_details
                && c.featured_media.media_details.sizes
                && c.featured_media.media_details.sizes.medium_large)
              ? c.featured_media.media_details.sizes.medium_large.source_url
              : c.featured_media.source_url
          ) : null;
          const inner = (
            <CardCampaign
              key={c.id}
              meta={null}
              title={c.title.rendered}
              paragraph={c.excerpt.rendered}
              imageUrl={imageUrl}
              renderCta={hover => (
                <MoreLink hover={hover} color={'red'}>
                  View More
                </MoreLink>
              )}
            />
          )

          if (c.external.status) {
            return (
              <div className={props.grid}>
                <a
                  href={c.external.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={c.id}
                  title={c.title.rendered}
                >
                  {inner}
                </a>
              </div>
            )
          }

          return (
            <div className={props.grid}>
              <Link to={`/campaigns/${c.slug}`} key={c.id}>
                {inner}
              </Link>
            </div>
          )
        })}
        {props.view ? (
          <div className="col-md-12">
            <Link style={{ width: '100%' }} to={'/campaigns'}>
              <ViewAll>View All Campaigns</ViewAll>
            </Link>
          </div>
        ) : null}
      </div>
    </Fragment>
  )
}

Campaigns.defaultProps = {
  grid: 'col-md-12',
  view: false,
  campaigns: []
}

export default Campaigns
