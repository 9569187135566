import React, { Fragment } from 'react'
import {
  NavIcon,
  CardHighlight,
  CardPerson,
  MoreLink,
  SectionFull,
  variables
} from '@website2018/da-dobsonville'
import SliderWrapper from '../Slider/Slider'
import Spacing from '../Spacing/Spacing'
import PersonModal from './PeopleModal'

class PeopleSlider extends React.Component {
  state = {
    isOpen: false,
    person: null
  }

  render() {
    const { highlight, people, title = null } = this.props
    //if (!people.length) return null;

    const members = people

    let count = 0
    let section = 0
    const sections = {}

    members.forEach(m => {
      count += 1

      if (sections[section] && sections[section].length) {
        sections[section].push(m)
      } else {
        sections[section] = [m]
      }

      if (count >= 1 && count % 6 === 0) {
        section += 1
      }
    })

    const { spacing, colors } = variables

    return (
      <Fragment>
        <SectionFull pt={'large'} color={'blue'} bgColor={'light'} mb={'none'} title={title}>
          {highlight && highlight.people ? (
            <div className={'col-md-12'}>
              {highlight.people.map(person => (
                <CardHighlight
                  imageUrl={person.featured_media ? person.featured_media.source_url : null}
                  title={person.title.rendered}
                  meta={person.role}
                  paragraph={person.excerpt.rendered}
                >
                  <Spacing bottom size={spacing.space2}>
                    <a href={`https://www.da.org.za/people/${person.slug}`}>
                      <MoreLink mb="medium">Read More</MoreLink>
                    </a>
                  </Spacing>
                  {person.facebook && (
                    <NavIcon iconType="bootstrap" iconName="facebook" iconColor={colors.socialFacebook}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={person.facebook}
                        title={person.facebook}
                      >
                        {person.title.rendered} on Facebook
                      </a>
                    </NavIcon>
                  )}
                  {person.twitter && (
                    <NavIcon iconType="bootstrap" iconName="twitter-x" iconColor={colors.socialTwitter}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.twitter.com/${person.twitter}`}
                        title={person.twitter}
                      >
                        Follow {person.twitter}
                      </a>
                    </NavIcon>
                  )}
                </CardHighlight>
              ))}
            </div>
          ) : null}
        </SectionFull>
        <SectionFull bgColor={'light'} mb={'none'} pt={'none'} pb={'large'} container={'container'}>
          <div className={'col-md-12'}>
            <div className={'row'}>
              {members.length ? (
                <SliderWrapper>
                  {Object.keys(sections).map(s => (
                    <div>
                      <div className="row">
                        {sections[s].map(m => (
                          <div className="col-md-6">
                            <CardPerson
                              title={`${m.prefname || m.firstname} ${m.lastname}`}
                              meta={
                                m.portfolio
                                  ? m.portfolio
                                  : m.government
                                  ? m.government.join(', ')
                                  : null
                              }
                              tel={m.cellphone}
                              email={m.email}
                              border={false}
                            >
                              <Spacing bottom size={spacing.space2}>
                                <MoreLink
                                  mb="medium"
                                  onClick={() => this.setState({ person: m, isOpen: true })}
                                >
                                  Read More
                                </MoreLink>
                              </Spacing>
                            </CardPerson>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </SliderWrapper>
              ) : null}
            </div>
          </div>
        </SectionFull>
        <PersonModal
          closeModal={() => this.setState({ person: null, isOpen: false })}
          isOpen={this.state.isOpen}
          p={this.state.person}
        />
      </Fragment>
    )
  }
}

export default PeopleSlider
