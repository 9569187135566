import React from 'react'
import {
  Aside,
  AsideOverlay,
  Copy,
  Heading,
  AsideSmall,
  ButtonTest,
  MoreLink
} from '@website2018/da-dobsonville'
import DonorForm from './DonorForm'
import Media from 'react-media'

import { Link } from 'react-router-dom'
import Spacing from '../Spacing/Spacing'
import FormContainer from '../../containers/Data/FormContainer'
import FormBuilderContainer from '../../containers/Data/FormBuilderContainer'

const Asides = props => {
  return [
    <aside>
      {props.asides.map(a => (
        <AsideSingle
          key={a.id}
          {...props}
          type={a.aside_options.type}
          imageUrl={(a.aside_options.image.sizes && a.aside_options.image.sizes.medium_large) || a.aside_options.image.url}
          {...a}
        />
      ))}
    </aside>
  ]
}

Asides.defaultProps = {
  asides: [],
  type: 'Aside'
}
export default Asides

const checkLink = urlString => {
  var pat = /^https?:\/\//i
  return !pat.test(urlString)
}

const AsideSingle = props => {
  const { type, aside_options } = props

  switch (type) {
    case 'Aside':
      return (
        <Aside
          key={props.id}
          id={props.id}
          imageUrl={props.imageUrl}
          meta={props.aside_options.aside.meta}
          title={props.aside_options.headline ? props.aside_options.headline : props.title.rendered}
          paragraph={props.content.rendered}
          url={props.aside_options.aside.url}
          cta={props.aside_options.aside.button_label}
          vertical
        >
          {aside_options.aside.add_donate_form === true ? (
            <DonorForm {...aside_options.aside} />
          ) : null}
        </Aside>
      )
    case 'AsideOverlay':
      return (
        <React.Fragment>
          <div className="visible-sm">
            <AsideSmall
              key={props.id}
              id={props.id}
              imageUrl={props.imageUrl}
              title={
                props.aside_options.headline ? props.aside_options.headline : props.title.rendered
              }
              url={props.aside_options.asidesmall.url}
              cta={props.aside_options.asidesmall.button_label}
            >
              {checkLink(props.aside_options.asideoverlay.url) ? (
                <Link to={props.aside_options.asideoverlay.url}>
                  <MoreLink>{props.aside_options.asideoverlay.button_label}</MoreLink>
                </Link>
              ) : (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={props.aside_options.asideoverlay.url}
                >
                  <MoreLink>{props.aside_options.asideoverlay.button_label}</MoreLink>
                </a>
              )}
            </AsideSmall>
          </div>
          <div className="hidden-sm">
            <AsideOverlay
              key={props.id}
              id={props.id}
              color={props.aside_options.asideoverlay.color}
              imageUrl={props.imageUrl}
              title={
                props.aside_options.headline ? props.aside_options.headline : props.title.rendered
              }
              paragraph={props.content.rendered}
            >
              {checkLink(props.aside_options.asideoverlay.url) ? (
                <Link to={props.aside_options.asideoverlay.url}>
                  <ButtonTest color="white" center>
                    {props.aside_options.asideoverlay.button_label}
                  </ButtonTest>
                </Link>
              ) : (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={props.aside_options.asideoverlay.url}
                >
                  <ButtonTest color="white" center>
                    {props.aside_options.asideoverlay.button_label}
                  </ButtonTest>
                </a>
              )}
            </AsideOverlay>
          </div>
        </React.Fragment>
      )
    case 'AsideSmall':
      return (
        <AsideSmall
          key={props.id}
          id={props.id}
          imageUrl={props.imageUrl}
          title={props.aside_options.headline ? props.aside_options.headline : props.title.rendered}
          url={props.aside_options.asidesmall.url}
          cta={props.aside_options.asidesmall.button_label}
        >
          {checkLink(props.aside_options.asidesmall.url) ? (
            <Link to={props.aside_options.asidesmall.url}>
              <MoreLink>{props.aside_options.asidesmall.button_label}</MoreLink>
            </Link>
          ) : (
            <a href={props.aside_options.asidesmall.url}>
              <MoreLink>{props.aside_options.asidesmall.button_label}</MoreLink>
            </a>
          )}
        </AsideSmall>
      )
    case 'Form':
      return (
        <Aside
          key={props.id}
          id={props.id}
          imageUrl={props.imageUrl}
          meta={props.aside_options.aside.meta}
          title={props.aside_options.headline ? props.aside_options.headline : props.title.rendered}
          paragraph={props.content.rendered}
          url={props.aside_options.url}
          cta={props.aside_options.aside.button_label}
          vertical
        >
          {props.aside_options.form && props.aside_options.form.form_type && (
            <FormContainer
              formId={props.aside_options.form.form_type}
              render={(form, onSubmit, meta) => (
                <FormBuilderContainer
                  meta={meta}
                  form={form}
                  page={props.page}
                  onSubmit={onSubmit}
                  inputClass={props.inputClass}
                />
              )}
            />
          )}
        </Aside>
      )
    default:
      return null
  }
}
