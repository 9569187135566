import React from 'react'
import Dropzone from 'react-dropzone'
import { StyleSheet, css } from 'aphrodite'
import { variables, Copy, Label } from '@website2018/da-dobsonville'

class DropzoneInput extends React.Component {
  constructor() {
    super()
    this.state = {
      accepted: [],
      rejected: []
    }
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { id, inputOptions = {} } = this.props

    this.setState({
      error: null
    })

    if (acceptedFiles.some(file => file.size >= this.props.maxSize)) {
      this.setState({
        error: 'File exceeded the 3MB size limit.'
      })

      return false
    }

    if (rejectedFiles.length > 0) {
      this.setState({
        error: 'File upload error.'
      })
      return false
    }

    if (inputOptions.onChange) {
      inputOptions.onChange(acceptedFiles, id)
    }
  }

  render() {
    const { inputClass, className, label, inputOptions = {} } = this.props
    const styles = StyleSheet.create({
      dropzone: {
        width: '100%',
        height: 'auto',
        borderWidth: '1px',
        borderColor: variables.colors.border,
        borderStyle: 'dashed',
        borderRadius: 0,
        background: variables.colors.bgLight,
        padding: '1.5rem 1.5rem 2rem'
      },
      fileList: {
        marginTop: '0.5rem'
      }
    })

    return (
      <div className={`${inputClass ? inputClass : className} form-group`}>
        <Label>{label}</Label>
        <div className="dropzone mb-2">
          <Dropzone
            accept={this.props.accept}
            className={css(styles.dropzone)}
            onDrop={this.onDrop}
          >
            <small>Click here or drop some files from your computer here.</small>
            <br />
            <small>Supported types: JPEG, PNG, PDF</small>
            <br />
          </Dropzone>
        </div>
        {this.state.error ? (
          <Copy mb="0" color="red" size="small">
            {this.state.error}
          </Copy>
        ) : null}
        {inputOptions.errors ? (
          <Copy mb="0" color="red" size="small">
            {inputOptions.errors}
          </Copy>
        ) : null}
        {this.props.error && (
          <Copy mb="0" color="red" size="small">
            {this.props.error}
          </Copy>
        )}
        {inputOptions.file && (
          <Copy mb="0" color="green" size="small">{`"${
            inputOptions.file.filename
          }" successfully added`}</Copy>
        )}
      </div>
    )
  }
}

DropzoneInput.defaultProps = {
  multiple: false,
  accept: 'application/pdf,image/jpeg,image/jpg,image/png',
  maxSize: 3145728,
  prompt: 'Select file to upload',
  promptIcon: 'arrowRight'
}

export default DropzoneInput
