import React from 'react'
import { Amount, Label } from '@website2018/da-dobsonville'

const DonorForm = ({ donation_options }) => {
  return (
    <Amount amounts={donation_options}>
      <div>
        <Label>Other amount</Label>
        <div className="input-group">
          <span className="input-group-addon" id="basic-addon1">
            R
          </span>
          <input type="text" className="form-control" id="amount" value="" />
        </div>
      </div>
    </Amount>
  )
}

DonorForm.defaultProps = {
  meta: null,
  button_label: 'Donate Now',
  url: 'https://www.da.org.za/donate',
  donation_options: null
}

export default DonorForm
